<template>
	<v-flex>
		<v-dialog :value="value" @input="$emit('input')" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Ajouter une équipe" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="$emit('input', false)" />
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Équipe" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-text-field label="Nom" hint="Nom de l'équipe"
														v-model="teamEntry.name"
														:rules="this.$fieldsRules.required('nom')"></v-text-field>
												</v-col>
												<v-col cols="12">
													<ImageUpload v-if="userInfo.company" :companyID="userInfo.company"
														type="team" label="Logo de l'équipe"
														@newdata="teamEntry.avatar=$event"></ImageUpload>
													<v-img height="200" v-if="teamEntry.avatar"
														:src="$functions.getImgLink(teamEntry.avatar, 200)" contain>
													</v-img>
													<ImageUpload v-if="userInfo.company" :companyID="userInfo.company"
														type="team" label="Image de bannière de l'équipe"
														@newdata="teamEntry.banner=$event"></ImageUpload>
													<v-img height="200" v-if="teamEntry.banner"
														:src="$functions.getImgLink(teamEntry.banner, 200)" contain>
													</v-img>
												</v-col>
												<v-col cols="12">
													<v-textarea hint="Description de l'équipe"
														label="Description de l'équipe" v-model="teamEntry.description"
														rows="4">
													</v-textarea>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12" align="center" justify="center">
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
														class="neon evidence mb-4" width="200" :disabled="teamEntry.name == ''"
														@click="submit()" @click.native="$emit('input', false)">
														Ajouter l'équipe
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</v-flex>
</template>

<script>
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		props: ['value', 'id', 'customers'],
		data() {
			return {
				userInfo: this.$models.user,

				pro: false,
				dialogDeleteItem: false,

				identifiant: null,

				date: null,
				menu: false,
				picker: new Date().toISOString().substr(0, 10),

				dialog: false,

				companyEntry: this.$models.company,
				teamEntry: this.$models.team
			};
		},

		components: {
			ImageUpload,
			ButtonTrigger
		},

		watch: {
			id: function () {
				this.preModifyCustomer(this.id);
			},
		},

		methods: {
			resetFields() {
				this.teamEntry = {
					'name': '',
					'description': '',
					'logo': '',
					'banner': ''
				}

				this.importIMG = {};
			},

			submit() {
				const team = {
					...this.teamEntry,

					'creationDate': new Date()
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("teams").add(team).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `L'équipe ${team.name} a été créée !`,
					});
				});

				this.resetFields();
			},
		},

		created() {
			this.resetFields();

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
			});
		}
	};

</script>
