<template>
	<div class="team">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />


		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog = !dialog" title="Équipes" content="Ajouter une Équipe" whiteText />
				<div v-for="team in teams" :key="team.id">
					<TeamBanner
						:team="team"
						:company="companyInfo"
						:people="people"
						:user="userInfo" />
				</div>
			</v-card>
		</v-container>

		<TeamDialog v-model="dialog" />

	</div>
</template>

<script>
	import Head from '@/components/graphics/head';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import TeamBanner from '@/components/modules/@company/team/TeamBanner';
	import TeamDialog from '@/components/modules/@company/team/TeamDialog';

	export default {
		head: {
			title: {
				inner: "Équipes"
			}
		},
		name: 'team',
		components: {
			Head,
			ButtonTrigger,
			TeamBanner,
			TeamDialog,
		},
		data() {
			return {
				dialog: false,
				teams: [],
				Head: {

					Title: 'Équipes',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				userInfo: this.$models.user,
				companyInfo: this.$models.company,

				people: []
			};
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("teams").onSnapshot((
					res) => {
					this.teams = [];

					res.forEach((document) => {
						this.teams.push({
							...document.data(),
							id: document.id
						});
					});
				});

				this.$db.collection("companies").doc(this.userInfo.company).onSnapshot((doc) => {
					this.companyInfo = {
						...doc.data(),
						id: doc.id
					}
				});
				
				this.people = [];
				this.$functions.queryDatabase(`companies/${this.userInfo.company}/users`).then((res) => {
					this.people.push({
						header: 'Utilisateurs'
					});

					res.forEach((user) => {
						this.$functions.queryDatabase(`users/${user.id}`).then((res2) => {

							res2.forEach((userData) => {
								this.people.push({
									'name': userData.data().lastName.toUpperCase() + " " + userData.data().firstName,
									'id': userData.id,
									'avatar': userData.data().avatar,
								});
							});

						});
					});
				});
			});

			this.currentUser = this.$firebase.auth().currentUser;
		}
	}

</script>
