<template>
	<div class="pb-2">
		<v-parallax
			height="300px"
			:src="$functions.getImgLink(team.banner) || 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'"
		>
			<v-container>
				<v-row>
					<v-col cols="6" class="text-justify">
						<h1>{{ team.name }}</h1>
						<h3 class="subtitle-1">{{nbrMembers}} membres</h3>
						<h4 class="subtitle-2 font-italic">{{ team.description }}</h4>
					</v-col>
					<v-col cols="5" class="align-right">
						<v-img :lazy-src="$functions.getImgLink(team.avatar, 50)"
							:src="$functions.getImgLink(team.avatar, 200)" contain max-height="125" right></v-img>
					</v-col>
					<v-col cols="1" class="align-right">
						<v-btn class="mx-2 mb-2" fab small dark outlined color="primary" elevation="8"
							@click="dialog.members = true">
							<v-icon>
								mdi-account-group
							</v-icon>
						</v-btn>
						<v-btn
							class="mx-2"
							fab
							small
							dark
							outlined
							color="primary"
							elevation="8"
							@click="dialog.params = true"
							v-if="selfUser.leader || !members.length"
						>
							<v-icon>
								mdi-cog-outline
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-parallax>

		<v-dialog v-model="dialog.params" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Ajouter une équipe" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="dialog.params = false" />
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Équipe" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-text-field label="Nom" hint="Nom de l'équipe" v-model="team.name"
														:rules="this.$fieldsRules.required('nom')"></v-text-field>
												</v-col>
												<v-col cols="12" sm="6">
													<ImageUpload v-if="company.id" :companyID="company.id" type="team"
														label="Logo de l'équipe" @newdata="team.avatar=$event" />
												</v-col>
												<v-col cols="12" sm="6">
													<ImageUpload v-if="company.id" :companyID="company.id" type="team"
														label="Image de bannière de l'équipe"
														@newdata="team.banner=$event" />
												</v-col>
												<v-col cols="12">
													<v-textarea hint="Description de l'équipe"
														label="Description de l'équipe" v-model="team.description"
														rows="4"></v-textarea>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col
													cols="12"
													align="center"
													justify="center"
												>
												<v-btn
													:block="$vuetify.breakpoint.xsOnly"
													outlined
													color="error"
													class="neon evidence mb-4 mx-2"
													width="200"
													@click="dialog.delete = true"
													@click.native="$emit('input', false)"
													v-if="selfUser.leader || !members.length"
												>
													Supprimer
												</v-btn>
												<v-btn
													:block="$vuetify.breakpoint.xsOnly"
													outlined
													color="info"
													class="neon evidence mb-4 mx-2"
													width="200"
													@click="updateTeam()"
													@click.native="$emit('input', false)"
													v-if="selfUser.leader || !members.length"
												>
													Modifier
												</v-btn>
												<v-btn
													:block="$vuetify.breakpoint.xsOnly"
													outlined
													color="success"
													class="neon evidence mb-4 mx-2"
													width="200"
													@click="dialog.params = false"
													@click.native="$emit('input', false)"
												>
													Fermer
												</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>


		<v-dialog v-model="dialog.members" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Gestion des membres" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="dialog.members = false" />
				<v-col
					cols="12"
					v-if="selfUser.leader || !members.length"
				>
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Ajouter un membre" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-autocomplete clearable v-model="selectPeople" :items="people"
														chips deletable-chips color="blue-grey lighten-2"
														label="Utilisateur" item-text="id" item-value="id">
														<template v-slot:selection="data">
															<v-chip v-bind="data.attrs" :input-value="data.selected"
																@click="data.select" close>
																<v-avatar left>
																	<v-img contain
																		:src="$functions.getImgLink(data.item.avatar, 50)">
																	</v-img>
																</v-avatar>
																{{ data.item.name }}
															</v-chip>
														</template>
														<template v-slot:item="data">
															<v-list-item-avatar>
																<v-img contain
																	:src="$functions.getImgLink(data.item.avatar, 50)">
																</v-img>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title>{{ data.item.name }}
																</v-list-item-title>
															</v-list-item-content>
														</template>
													</v-autocomplete>
												</v-col>
												<v-col cols="12" align="center" justify="center">
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="primary"
														class="neon evidence mb-4 mx-2" width="200" @click="addToTeam()"
														@click.native="$emit('input', false)">
														Ajouter un membre
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>


				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Membres" :noButton="false" />
												</v-col>
												<v-col cols="12" v-for="member in members" :key="member.id">
													<v-card class="mx-auto" outlined>
														<v-list-item three-line v-if="member.lastName">
															<v-list-item-content class="pb-0">
																<v-list-item-title class="headline mb-4">
																	<span v-if="member.leader">👑 </span>{{ member.firstName }} {{ member.lastName }}
																</v-list-item-title>
																<v-list-item-subtitle>
																	<p v-if="member.leader">Chef de l'équipe {{ team.name }}</p>
																	<p>{{ member.job }} • {{ member.email }}</p>
																</v-list-item-subtitle>
															</v-list-item-content>
															<v-list-item-avatar size="80">
																<span v-if="!member.avatar"
																	class="font-weight-regular">{{ member.firstName[0] }}{{ member.lastName[0] }}</span>
																<v-img v-else
																	:src="$functions.getImgLink(member.avatar, 50)">
																</v-img>
															</v-list-item-avatar>
														</v-list-item>
														<v-card-actions>
															<v-btn
																:block="$vuetify.breakpoint.xsOnly"
																outlined
																color="primary lighten-2"
																class="neon evidence mb-4 mx-2"
																width="200"
																v-if="!member.leader && selfUser.leader"
																@click="promoteUser(member)"
															>
																Promouvoir
															</v-btn>
															<v-btn
																:block="$vuetify.breakpoint.xsOnly"
																outlined
																color="warning lighten-2"
																class="neon evidence mb-4 mx-2"
																width="200"
																v-if="member.leader && selfUser.leader"
																@click="demoteUser(member)"
															>
																Rétrograder
															</v-btn>

															<v-btn
																:block="$vuetify.breakpoint.xsOnly"
																outlined
																color="error"
																class="neon evidence mb-4 mx-2"
																width="200"
																v-if="selfUser.leader"
																@click="removeFromTeam(member)"
															>
																Retirer de l'équipe
															</v-btn>
														</v-card-actions>
													</v-card>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
														class="neon evidence mb-4 mx-2 float-right" width="200"
														@click="dialog.members = false">
														Fermer
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

			</v-card>
		</v-dialog>


		<v-dialog v-model="dialog.delete" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger
					:title="`Voulez-vous supprimer l'équipe ${team.name} ?`"
					small
					whiteText
					:noButton="$vuetify.breakpoint.smAndUp" icon content="" @click.native="dialog.params = false" />
				<v-col cols="12">
					<v-card class="bt-error">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<p>Après avoir cliqué sur "Confirmer la suppression", l'équipe ne
														sera plus disponible et tous ses
														membres n'y auront plus accès.</p>
													<h3 class="mb-3">Les membres suivants seront affectés</h3>
													<v-row>
														<v-col
															v-for="member in members"
															:key="member.id"
															cols="auto"
															class="pr-0"
														>
															<v-chip outlined>
																<v-avatar left>
																	<v-img :src="$functions.getImgLink(member.avatar, 50)"></v-img>
																</v-avatar>
																{{ member.firstName }} {{ member.lastName[0] }}.
															</v-chip>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-error">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12" align="center" justify="center">
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error"
														class="neon evidence mb-4 mx-2" width="200"
														@click="deleteTeam()" @click.native="$emit('input', false)">
														Supprimer
													</v-btn>
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
														class="neon evidence mb-4 mx-2" width="200"
														@click="dialog.delete = false"
														@click.native="$emit('input', false)">
														Fermer
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		props: {
			'team': {
				type: Object
			},
			'company': {
				type: Object
			},
			'people': {
				type: Array
			},
			'user': {
				type: Object
			}
		},
		components: {
			ImageUpload,
			ButtonTrigger
		},
		data() {
			return {
				dialog: {
					params: false,
					delete: false,
					members: false
				},
				nbrMembers: '',

				members: [],

				selfUser: {},

				selectPeople: ''
			}
		},
		methods: {
			addToTeam() {
				let data = {
					addedDate: new Date()
				};

				if (!this.members.length) data.leader = true;

				this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).collection("members").doc(this.selectPeople).set(data, {
					merge: true
				}).then(() => {
					this.dialog.addMember = false;

					this.$store.dispatch('setSnackbar', {
						text: `L'équipe a été modifiée !`
					});
				});
			},
			removeFromTeam(user) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).collection("members").doc(user.id), `${user.firstName} a été retiré de l'équipe ${this.team.name}`);

				this.dialog.addMember = false;
			},

			promoteUser(user) {
				this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).collection("members").doc(user.id).update({
					leader: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `${user.firstName} est maintenant chef d'équipe !`
					});
				});
			},
			demoteUser(user) {
				this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).collection("members").doc(user.id).update({
					leader: false
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `${user.firstName} n'est plus chef d'équipe.`
					});
				});
			},

			updateTeam() {
				this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).set(this
					.team, {
						merge: true
					});

				this.$store.dispatch('setSnackbar', {
					text: `L'équipe a été modifiée !`
				});

				this.dialog.params = false;
			},
			deleteTeam(){
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id), "L'équipe a été supprimée !");

				this.dialog.delete = false;
			}
		},
		created() {
			this.$db.collection("companies").doc(this.company.id).collection("teams").doc(this.team.id).collection("members").onSnapshot((res) => {
				let members = [];

				this.nbrMembers = res.size;

				res.forEach((document) => {
					this.$db.collection("users").doc(document.id).get().then((doc) => {
						let data = {
							...doc.data(),
							...document.data(),
							id: doc.id
						};

						members.push(data);

						if (document.id === this.user.id) this.selfUser = data;
					});
				});

				this.members = members;
			});
		}
	}

</script>
